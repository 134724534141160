import {Component, OnInit} from '@angular/core';

import {ApiResponseModel} from '../../shared/api-response.model';
import {AuthService} from '../../auth/auth.service';
import {BackendService} from '../../shared/backend.service';
import {NotificationService} from '../../shared/notification/notification.service';
import {Router} from '@angular/router';
import {User} from '../../auth/user.model';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-register',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  isLoading = false;
  user: User;

  constructor(private ntf: NotificationService, private backendService: BackendService, private router: Router,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.user = this.authService.getUser();
  }

  onSubmit(f) {
    this.isLoading = true;
    this.ntf.hide();
    this.backendService.api('portal.createMember', f.value)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (rsp: ApiResponseModel) => {
          if (rsp.statusCode === 'SUCCESS') {
            this.ntf.success(`Profile created successfully! Please re-login to continue...`);
            this.authService.logout();
          } else {
            this.ntf.error(`Error: ${rsp.userFriendlyMessage}`);
          }
        },
        rsp => {
          this.ntf.error(`Error: ${rsp.userFriendlyMessage}`);
        }
      );


  }
}
