import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { LaddaModule } from 'angular2-ladda';
import { Angulartics2Module } from 'angulartics2';
import { SafePipeModule } from 'safe-pipe';
import { VgBufferingModule } from 'videogular2/compiled/src/buffering/buffering';
import { VgControlsModule } from 'videogular2/compiled/src/controls/controls';
import { VgCoreModule } from 'videogular2/compiled/src/core/core';
import { VgOverlayPlayModule } from 'videogular2/compiled/src/overlay-play/overlay-play';
import { VgStreamingModule } from 'videogular2/compiled/src/streaming/streaming';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MeditatorModule } from './meditator/meditator.module';
import { NavModule } from './nav/nav.module';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { MaterialModule } from './shared/material.module';
import { NotificationComponent } from './shared/notification/notification.component';

@NgModule({
  declarations: [AppComponent, NotificationComponent, ConfirmDialogComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    NavModule,
    MeditatorModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    LaddaModule,
    HttpClientModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    SafePipeModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgStreamingModule,
    Angulartics2Module.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: MatSnackBarRef, useValue: {} },
    { provide: MAT_SNACK_BAR_DATA, useValue: {} }
  ],
  entryComponents: [NotificationComponent, ConfirmDialogComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faFacebookF, faGoogle);
  }
}
