import {Component, Input} from '@angular/core';
import {Program} from '../../program';

@Component({
  selector: 'app-digital-veera-training-1',
  templateUrl: './digital-veera-training-1.component.html',
  styles: []
})
export class DigitalVeeraTraining1Component {
  @Input() program: Program;
  constructor() {
  }
}
