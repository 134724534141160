import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '@environment';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ApiResponseEntity } from 'src/app/shared/api-response.model';
import { Member } from 'src/app/shared/services/member/member.entity';
import { MemberService } from 'src/app/shared/services/member/member.service';
import { ScrollService } from 'src/app/shared/services/scroll/scroll.service';
import { FormGroupDto } from 'src/app/shared/types/form-group-dto.type';
import { VolunteerRegistrationDto } from '../../models/volunteer-registration.dto';
import { VolunteerRegistrationEntity } from '../../models/volunteer-registration.entity';
import { SiaService, VolunteeringStatus } from '../../sia.service';
import {
  ProfessionalDMSkillsLabels,
  ProfessionalDMSkillsOptions,
  ProfessionalITSkillsLabels,
  ProfessionalITSkillsOptions,
  ProfessionalOtherSkillsLabels,
  ProfessionalOtherSkillsOptions,
  ProgramsCompletedLabels,
  ProgramsCompletedOptions,
  ResourcesLabels,
  ResourcesOptions,
  SocialMediaInfluencersLabels,
  SocialMediaInfluencersOptions,
  SydneyVolunteeringDays,
  SydneyVolunteeringDaysOptions,
  VolunteeringAvailabilityOptions
} from './volunteer-registration.constants';

@Component({
  selector: 'app-volunteer-registration',
  templateUrl: './volunteer-registration.component.html',
  styleUrls: ['./volunteer-registration.component.scss']
})
export class VolunteerRegistrationComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();

  registrationSubmitted = false;
  submissionError = '';

  memberInfo$ = this.authService.memberInfo$.pipe(
    switchMap(user => {
      this.memberService.loadMember(user.memberId);
      return this.memberService.member$;
    })
  );

  loading = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private memberService: MemberService,
    private siaService: SiaService,
    private scrollService: ScrollService
  ) {
    this.registrationFG = this.fb.group(this.formGroupField);
  }

  registrationFG: FormGroup;

  programsCompletedOptions = ProgramsCompletedOptions;

  professionalITSkillsOptions = ProfessionalITSkillsOptions;

  professionalDMSkillsOptions = ProfessionalDMSkillsOptions;

  professionalOtherSkillsOptions = ProfessionalOtherSkillsOptions;

  resourcesOptions = ResourcesOptions;

  socialMediaInfluencersOptions = SocialMediaInfluencersOptions;

  sydneyVolunteeringDaysOptions = SydneyVolunteeringDaysOptions;

  volunteeringAvailabilityOptions = VolunteeringAvailabilityOptions;

  ngOnInit() {
    this.siaService.getVolunteeringStatus(this.authService.user.memberId).subscribe((status: VolunteeringStatus) => {
      if (!environment.production) return;
      if (!status.isRegistered) return;
      if (!status.hasContributed) {
        this.siaService.goToVolunteerContribution();
        return;
      }
      this.siaService.goToVolunteerRegistered();
    });

    this.memberInfo$.pipe(takeUntil(this.destroy$)).subscribe(memberInfo => {
      this.registrationFG.patchValue(this.mapToDto(memberInfo));
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private get formGroupField(): FormGroupDto<VolunteerRegistrationDto> {
    return {
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      mobileNumber: new FormControl('', [Validators.required]),
      whatsappNumber: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      postCode: new FormControl('', [Validators.required]),
      age: new FormControl('', [Validators.required]),
      occupation: new FormControl('', [Validators.required]),
      company: new FormControl(''),
      volunteerMeetLocation: new FormControl(''),
      programsCompleted: new FormControl('', [Validators.required]),
      programsCompletedOther: new FormControl(''),
      professionalITSkills: new FormControl(''),
      professionalITSkillsOther: new FormControl(''),
      professionalDMSkills: new FormControl(''),
      professionalDMSkillsOther: new FormControl(''),
      professionalOtherSkills: new FormControl(''),
      professionalOtherSkillsOther: new FormControl(''),
      resources: new FormControl(''),
      resourcesOther: new FormControl(''),
      canSpeakMandarin: new FormControl('', [Validators.required]),
      corporateConnections: new FormControl(''),
      mediaConnections: new FormControl(''),
      socialMediaInfluencers: new FormControl(''),
      socialMediaInfluencersOther: new FormControl(''),
      socialMediaInfluencersXDetails: new FormControl(''),
      socialMediaInfluencersInstagramDetails: new FormControl(''),
      socialMediaInfluencersFacebookDetails: new FormControl(''),
      socialMediaInfluencersTikTokDetails: new FormControl(''),
      socialMediaInfluencersYoutubeDetails: new FormControl(''),
      socialMediaInfluencersOtherDetails: new FormControl(''),
      sydneyVolunteeringDays: new FormControl(''),
      sydneyVolunteeringDaysOther: new FormControl(''),
      comments: new FormControl(''),
      memberId: new FormControl(''),
      volunteeringAvailability: new FormControl('', [Validators.required])
    };
  }

  getFormField(fieldName: keyof VolunteerRegistrationDto): AbstractControl {
    return this.registrationFG.get(fieldName);
  }

  register() {
    if (this.registrationFG.invalid) {
      this.registrationFG.markAllAsTouched();
      this.scrollService.scrollToFirstInvalidFormControl(this.registrationFG);
      return;
    }

    this.loading = true;

    this.memberService.registerAsVolunteer(this.mapToEntity(this.registrationFG.value)).subscribe(
      (response: ApiResponseEntity<Member>) => {
        if (response.statusCode === 'SUCCESS') {
          this.siaService.goToVolunteerContribution('reg');
        } else {
          this.submissionError = response.userFriendlyMessage;
          console.error(`Error: ${response.userFriendlyMessage}`);
        }
        this.loading = false;
      },
      error => console.error(`Error: ${error}`)
    );
  }

  isOtherSelected(fieldName: keyof VolunteerRegistrationDto) {
    const selectedValue = this.getFormField(fieldName).value;
    const isSelected = selectedValue && selectedValue.includes('other');
    const otherControl = this.getFormField((fieldName + 'Other') as keyof VolunteerRegistrationDto);
    if (isSelected) {
      otherControl.setValidators([Validators.required]);
    } else {
      otherControl.clearValidators();
      otherControl.setErrors(null);
    }
    return isSelected;
  }

  private mapToDto(memberInfo: Member): VolunteerRegistrationDto {
    if (!memberInfo) {
      return {} as VolunteerRegistrationDto;
    }
    return {
      memberId: memberInfo.memberId,
      firstName: memberInfo.firstName,
      lastName: memberInfo.lastName,
      email: memberInfo.email,
      mobileNumber: memberInfo.mobilePhone,
      whatsappNumber: memberInfo.mobilePhone,
      country: memberInfo.homeCountry,
      state: memberInfo.homeState,
      postCode: memberInfo.homePincode,
      occupation: memberInfo.occupation
    } as VolunteerRegistrationDto;
  }

  private mapToEntity(dto: VolunteerRegistrationDto): VolunteerRegistrationEntity {
    const options = {
      programsCompleted: this.buildOptionsPayload(dto, 'programsCompleted', ProgramsCompletedLabels as any),
      professionalSkills: {
        it: this.buildOptionsPayload(dto, 'professionalITSkills', ProfessionalITSkillsLabels as any),
        dm: this.buildOptionsPayload(dto, 'professionalDMSkills', ProfessionalDMSkillsLabels as any),
        others: this.buildOptionsPayload(dto, 'professionalOtherSkills', ProfessionalOtherSkillsLabels as any)
      },
      resources: this.buildOptionsPayload(dto, 'resources', ResourcesLabels as any),
      socialMediaInfluencers: this.buildOptionsPayload(
        dto,
        'socialMediaInfluencers',
        SocialMediaInfluencersLabels as any
      ),
      socialMediaInfluencersDetails: {
        X: dto.socialMediaInfluencersXDetails,
        Instagram: dto.socialMediaInfluencersInstagramDetails,
        Facebook: dto.socialMediaInfluencersFacebookDetails,
        TikTok: dto.socialMediaInfluencersTikTokDetails,
        Youtube: dto.socialMediaInfluencersYoutubeDetails,
        Other: dto.socialMediaInfluencersOtherDetails
      },
      speakMandarin: dto.canSpeakMandarin.toString(),
      contacts: {
        corporate: dto.corporateConnections,
        media: dto.mediaConnections
      },
      sydneyVolunteering: dto.sydneyVolunteeringDays ? 'Yes' : 'No',
      dedicatedLeaveDays: dto.sydneyVolunteeringDaysOther || SydneyVolunteeringDays[dto.sydneyVolunteeringDays],
      otherIdeas: dto.comments,
      volunteerMeetLocation: dto.volunteerMeetLocation,
      volunteeringAvailability: dto.volunteeringAvailability
    };

    const homeAddress = {};
    this.toDotNotation(homeAddress, {
      homeAddress: {
        country: dto.country,
        state: dto.state,
        pincode: dto.postCode
      }
    });

    return {
      ...homeAddress,
      firstName: dto.firstName,
      lastName: dto.lastName,
      email: dto.email,
      mobileNumber: dto.mobileNumber,
      whatsappNumber: dto.whatsappNumber,
      age: dto.age,
      occupation: dto.occupation,
      companyName: dto.company,
      options: JSON.stringify(options),
      programId: this.siaService.volunteeringProgramId.toString(),
      memberId: dto.memberId,
      updateContact: true
    } as VolunteerRegistrationEntity;
  }

  buildOptionsPayload<T>(
    dto: VolunteerRegistrationDto,
    prop: keyof VolunteerRegistrationDto,
    type: { new (): any }
  ): T {
    const retVal = {} as T;
    Object.keys(type).forEach(key => {
      var normalProp = (dto[prop] as string[]).includes(key).toString();
      var otherProp = dto[prop + 'Other'];
      retVal[key] = key == 'other' ? otherProp : normalProp;
    });
    return retVal;
  }

  // https://stackoverflow.com/questions/69246784/how-to-convert-a-js-object-into-a-dot-notation-string
  private toDotNotation(into, obj, prefix = []) {
    Object.entries(obj).forEach(([key, val]) => {
      if (typeof val === 'object' && !Array.isArray(val)) this.toDotNotation(into, val, [...prefix, key]);
      else into[[...prefix, key].join('.')] = val;
    });
  }
}
