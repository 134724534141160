import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../auth/auth.guard';
import { RegisterFundayComponent } from './funday/register-funday';
import { RegisterMsrComponent } from './msr/register-msr';
import { RegisterComponent } from './register.component';
import { RegisterSatsangComponent } from './satsang/register-satsang.component';
import { VolunteerMeetFestiveCelebrationComponent } from './sia-2024/volunteer/volunteer-meet-festive-celebration/volunteer-meet-festive-celebration.component';
import { RegisterSmmPcComponent } from './smm-practice-correction/register-smm-pc.component';
import { RegisterGuruPurnimaComponent } from './guru-purnima/register-guru-purnima';
import { RegisterSmmGpComponent } from './smm-guided-practice/register-smm-gp.component';

export const routes: Routes = [
  { path: 'register/:programId', component: RegisterComponent, canActivate: [AuthGuard] },
  { path: 'register/:type/:programId', component: RegisterComponent, canActivate: [AuthGuard] },
  { path: 'register-msr', component: RegisterMsrComponent, canActivate: [AuthGuard] },
  { path: 'register-guru-purnima', component: RegisterGuruPurnimaComponent, canActivate: [AuthGuard] },
  { path: 'register-funday', component: RegisterFundayComponent, canActivate: [AuthGuard] },
  { path: 'register-smm-pc/:programIds', component: RegisterSmmPcComponent, canActivate: [AuthGuard] },
  { path: 'register-smm-gp/:programIds', component: RegisterSmmGpComponent, canActivate: [AuthGuard] },
  { path: 'register-satsang/:programIds', component: RegisterSatsangComponent, canActivate: [AuthGuard] },
  {
    path: 'sia/volunteer-meet-festive-celebration/:programIds',
    component: VolunteerMeetFestiveCelebrationComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class Routing {}
