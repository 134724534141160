import { Component, Input } from '@angular/core';
import { Program } from '../../program';

@Component({
  selector: 'app-friends-of-soil-a-webinar',
  templateUrl: './friends-of-soil-a-webinar.component.html',
  styleUrls: ['../../register.component.scss']
})
export class FriendsOfSoilAwebinarComponent {
  @Input() program: Program;
  constructor() {
  }
}
