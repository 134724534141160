import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { environment } from '@environment';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/auth/user.model';
import { MemberService } from 'src/app/shared/services/member/member.service';
import { ScrollService } from 'src/app/shared/services/scroll/scroll.service';
import { Options } from '../../models/volunteer-status.entity';
import { SiaService, VolunteeringStatus } from '../../sia.service';
import { VolunteeringAvailabilityOptions } from '../registration/volunteer-registration.constants';

@Component({
  selector: 'app-volunteer-contribution',
  templateUrl: './volunteer-contribution.component.html',
  styleUrls: ['./volunteer-contribution.component.scss']
})
export class VolunteerContributionComponent implements OnInit {
  programId: number = this.siaService.volunteeringProgramId;
  user: User = this.authService.user;
  memberId: number = this.user.memberId;

  redirectSrc?: string;

  constructor(
    private authService: AuthService,
    private siaService: SiaService,
    private memberService: MemberService,
    private route: ActivatedRoute,
    private scrollService: ScrollService
  ) {}

  volunteeringAvailabilityOptions = VolunteeringAvailabilityOptions;

  ccy = 'AUD';
  defaultDonationAmounts = [150, 300, 500];
  minAmt = 150;

  donationFG = new FormGroup({
    volunteeringAvailability: new FormControl(undefined, [Validators.required]),
    radioAmount: new FormControl(null, [Validators.required]),
    otherAmount: new FormControl(null)
  });

  options: Options;

  loading = true;
  redirecting$ = this.memberService.redirecting$;
  submitting$ = this.siaService.submitting$;
  submissionError$ = this.siaService.submissionError$;

  get radioAmt(): AbstractControl {
    return this.donationFG.get('radioAmount');
  }

  get otherAmt(): AbstractControl {
    return this.donationFG.get('otherAmount');
  }

  get hardshipFormLink(): string {
    const firstName = this.user.firstName;
    const lastName = this.user.lastName;
    const mobile = this.user.phoneNumber;
    const email = this.user.email;
    return `https://www.jotform.com/232769130747058?edit=F&firstName=${firstName}&lastName=${lastName}&mobile=${mobile}&email=${email}`;
  }

  get volunteeringAvailability(): string {
    return this.donationFG.get('volunteeringAvailability').value;
  }

  get hasSubmittedVolAvailability(): boolean {
    return !this.donationFG.contains('volunteeringAvailability');
  }

  ngOnInit() {
    this.siaService.getVolunteeringStatus(this.memberId).subscribe((status: VolunteeringStatus) => {
      this.options = status.options;
      if (status.hasCompletedVolunteerAvailability) {
        this.donationFG.removeControl('volunteeringAvailability');
      }
      this.loading = false;
      if (!environment.production) return;
      if (status.hasContributed) {
        this.siaService.goToVolunteerRegistered();
      }
    });

    this.route.queryParams.subscribe((params: Params) => {
      this.redirectSrc = params['src'];
    });

    this.radioAmt.valueChanges.subscribe(value => {
      if (value === 'other') {
        this.otherAmt.setValidators([Validators.required, Validators.min(this.minAmt)]);
        this.otherAmt.markAsUntouched();
        this.otherAmt.markAsPristine();
      } else {
        this.otherAmt.clearValidators();
        this.otherAmt.setValue(null);
      }
      this.otherAmt.updateValueAndValidity();
    });
  }

  submit(): void {
    if (!this.validateForm()) return;

    if (this.hasSubmittedVolAvailability) {
      this.payVolunteerContribution();
      return;
    }

    this.siaService.submitVolAvailability(this.memberId, this.options, this.volunteeringAvailability, () =>
      this.payVolunteerContribution()
    );
  }

  private payVolunteerContribution(): void {
    let amount: number;
    if (this.radioAmt.value === 'other') {
      amount = this.otherAmt.value;
    } else {
      amount = this.radioAmt.value;
    }
    this.memberService.payVolunteerContribution(this.programId, this.memberId, amount, '/sia/volunteer/thank-you');
  }

  navigateToOptOut(): void {
    if (!this.validateForm(false)) return;

    if (this.hasSubmittedVolAvailability) {
      this.siaService.goToVolunteerRegistered();
      return;
    }

    this.siaService.submitVolAvailability(this.memberId, this.options, this.volunteeringAvailability, () =>
      this.siaService.goToVolunteerRegistered()
    );
  }

  openHardshipForm(): void {
    if (!this.validateForm(false)) return;

    if (this.hasSubmittedVolAvailability) {
      window.open(this.hardshipFormLink, '_blank');
      return;
    }

    this.siaService.submitVolAvailability(this.memberId, this.options, this.volunteeringAvailability, () =>
      window.open(this.hardshipFormLink, '_blank')
    );
  }

  private validateForm(validateAmount: boolean = true): boolean {
    const scrollOffset = 56;
    let invalid: AbstractControl;

    if (validateAmount && this.donationFG.invalid) {
      invalid = this.donationFG;
    } else if (!this.hasSubmittedVolAvailability && this.donationFG.get('volunteeringAvailability').invalid) {
      invalid = this.donationFG.get('volunteeringAvailability');
    }

    if (invalid) {
      invalid.markAllAsTouched();
      this.scrollService.scrollToFirstInvalidFormControl(this.donationFG, scrollOffset);
      return false;
    }

    return true;
  }
}
