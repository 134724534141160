import {Component} from '@angular/core';

@Component({
  selector: 'app-smm-practice-qna',
  templateUrl: './smm-practice-qna.component.html',
  styleUrls: ['../../register.component.scss']
})
export class SmmPracticeQnaComponent {
  constructor() {
  }
}
