import { Component, Input } from '@angular/core';
import { Program } from '../../program';

@Component({
  selector: 'app-earth-buddy-celebration',
  templateUrl: './earth-buddy-celebration.component.html',
  styleUrls: ['../../register.component.scss']
})
export class EarthBuddyCelebrationComponent {
  @Input() program: Program;
  constructor() {
  }
}
