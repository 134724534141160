import {Component, Input} from '@angular/core';
import {Program} from '../../program';

@Component({
  selector: 'app-planet-champion-training',
  templateUrl: './planet-champion-training.component.html',
  styleUrls: ['../../register.component.scss']
})
export class PlanetChampionTrainingComponent {
  @Input() program: Program;
  constructor() {
  }
}
