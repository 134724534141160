import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiResponseModel } from '../shared/api-response.model';
import { BackendService } from '../shared/backend.service';
import { User } from './user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private router: Router, private http: HttpClient, private backend: BackendService) {}
  authChange = new Subject<boolean>();
  user: User;

  private memberInfo = new BehaviorSubject<User>({} as User);
  public memberInfo$ = this.memberInfo.asObservable();

  checkAuth(returnUrl = '') {
    return new Observable<boolean>(observer => {
      // console.log(`checking auth ${JSON.stringify(this.user)}`);
      if (this.user) {
        return observer.next(true);
      }

      this.backend.api('portal.getMember', {}).subscribe(
        (response: ApiResponseModel) => {
          if (response.statusCode === 'SUCCESS') {
            this.user = this.checkAdmin(response.object);
            this.authChange.next(true);
            this.memberInfo.next(response.object);
            observer.next(true);
          } else {
            this.logout(returnUrl);
            observer.error();
          }
        },
        () => {
          this.logout(returnUrl);
          observer.error();
        }
      );
    });
  }

  checkAdmin(user) {
    if (user.email.indexOf('@ishayoga.live') !== -1) {
      return {
        firstName: 'Admin',
        lastName: 'User',
        memberId: 9999999,
        email: user.email,
        isMeditator: true,
        isSCK: true
      };
    }
    return user;
  }

  logout(returnUrl = '') {
    this.user = null;
    this.authChange.next(false);
    this.backend.api('portal.logout', {}).subscribe(
      (response: ApiResponseModel) => {
        this.router.navigate(['/login'], { queryParams: { returnUrl } }).then();
      },
      () => {
        this.router.navigate(['/login'], { queryParams: { returnUrl } }).then();
      }
    );
  }

  getUser() {
    return this.user;
  }

  isAdmin() {
    const user = this.getUser();
    return [
      'sjawaji@gmail.com',
      'thulasidhar@gmail.com',
      'ashwinivelangaya@gmail.com',
      'h.pranav@gmail.com',
      'ganga.jogur@gmail.com',
      'thrinath.seelam@gmail.com'
    ].includes(user && user.email);
  }
}
