import {Component} from '@angular/core';

@Component({
  selector: 'app-sages-of-india-part-2',
  templateUrl: './sages-of-india-part-2.component.html',
  styleUrls: ['../../register.component.scss']
})
export class SagesOfIndiaPart2Component {
  constructor() {
  }
}
