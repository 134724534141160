import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../auth/auth.guard';
import {CreateComponent} from './create/create.component';
import {ReconcileComponent} from './reconcile/reconcile.component';


export const routes: Routes = [
  {path: 'create', component: CreateComponent, canActivate: [AuthGuard]},
  {path: 'reconcile', component: ReconcileComponent, canActivate: [AuthGuard]},
];

export const Routing: ModuleWithProviders = RouterModule.forChild(routes);
