import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {MatSidenavModule} from '@angular/material';
import {RouterModule} from '@angular/router';
import {MaterialModule} from '../shared/material.module';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';

@NgModule({
  imports: [
    CommonModule,
    FlexModule,
    MaterialModule,
    RouterModule,
    FlexLayoutModule,
    MatSidenavModule
  ],
  declarations: [HeaderComponent, FooterComponent],
  exports: [HeaderComponent, FooterComponent],
})
export class NavModule {
}
