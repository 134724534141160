import { Component } from '@angular/core';

@Component({
  selector: 'app-vol-meet-ieupdate',
  templateUrl: './vol-meet-ieupdate.component.html',
  styleUrls: ['../../register.component.scss']
})
export class VolMeetIeupdateComponent {
  constructor() {
  }
}
