import { Component, Input, OnInit } from '@angular/core';
import { Program } from '../../program';

@Component({
  selector: 'app-deepening-hatha-yoga',
  templateUrl: './deepening-hatha-yoga.component.html',
  styleUrls: ['./deepening-hatha-yoga.component.scss']
})
export class DeepeningHathaYogaComponent implements OnInit {
  @Input() program: Program;  
  constructor() { }

  ngOnInit() {
  }

}
