import {Component} from '@angular/core';

@Component({
  selector: 'app-free-tn-temples',
  templateUrl: './free-tn-temples.component.html',
  styleUrls: ['../../register.component.scss']
})
export class FreeTnTemplesComponent {
  constructor() {
  }
}
