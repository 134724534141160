import { Component, Input } from '@angular/core';
import { Program } from '../../program';

@Component({
  selector: 'app-save-soil-corporate-partnership-outreach',
  templateUrl: './save-soil-corporate-&-partnership-outreach.html',
  styleUrls: ['../../register.component.scss']
})
export class SaveSoilCorporatePartnershipOutreachComponent {
  @Input() program: Program;
  constructor() {
  }
}
