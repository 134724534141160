import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponseEntity } from 'src/app/shared/api-response.model';
import { Member } from 'src/app/shared/services/member/member.entity';
import { MemberService } from 'src/app/shared/services/member/member.service';
import { Options, VolunteerStatusEntity } from './models/volunteer-status.entity';
import { SIA_VOL_REG_PROGRAM_ID_PROD, SIA_VOL_REG_PROGRAM_ID_QA } from './sia.constants';

export interface VolunteeringStatus {
  isRegistered: boolean;
  hasContributed: boolean;
  hasCompletedVolunteerAvailability: boolean;
  options: Options;
}

@Injectable({
  providedIn: 'root'
})
export class SiaService {
  private volunteeringPath = 'sia/volunteer';

  private submissionError = new BehaviorSubject<String>('');
  public submissionError$ = this.submissionError.asObservable();

  private submitting = new BehaviorSubject<boolean>(false);
  submitting$ = this.submitting.asObservable();

  constructor(private memberService: MemberService, private router: Router) {}

  get volunteeringProgramId(): number {
    return environment.production ? SIA_VOL_REG_PROGRAM_ID_PROD : SIA_VOL_REG_PROGRAM_ID_QA;
  }

  getVolunteeringStatus(memberId: number): Observable<VolunteeringStatus> {
    const volunteerStatusParams = { programId: this.volunteeringProgramId, memberId };
    return this.memberService.getVolunteerStatus(volunteerStatusParams).pipe(
      map((res: ApiResponseEntity<VolunteerStatusEntity>) => ({
        isRegistered: !!res.object,
        hasContributed: !!res.object && !!res.object.transactionId,
        hasCompletedVolunteerAvailability:
          !!res.object && !!res.object.options && !!res.object.options.volunteeringAvailability,
        options: res.object ? res.object.options : null,
      }))
    );
  }

  goToVolunteerRegistration(): void {
    this.router.navigate([`${this.volunteeringPath}/registration`]);
  }

  goToVolunteerContribution(src?: string): void {
    const path = `${this.volunteeringPath}/contribution`;
    if (src) {
      this.router.navigate([path], { queryParams: { src } });
    } else {
      this.router.navigate([path]);
    }
  }

  goToVolunteerRegistered(): void {
    this.router.navigate([`${this.volunteeringPath}/thank-you`]);
  }

  submitVolAvailability(memberId: number, opts: Options, volunteeringAvailability: string, onSuccess: () => any): void {
    this.submitting.next(true);
    const options = JSON.stringify({
      ...opts,
      volunteeringAvailability
    });
    const payload = {
      options,
      programId: this.volunteeringProgramId.toString(),
      memberId: memberId
    };

    this.memberService.registerAsVolunteer(payload).subscribe(
      (response: ApiResponseEntity<Member>) => {
        if (response.statusCode === 'SUCCESS') {
          onSuccess();
        } else {
          this.submissionError.next(response.userFriendlyMessage);
          console.error(`Error: ${response.userFriendlyMessage}`);
        }
        this.submitting.next(false);
      },
      error => console.error(`Error: ${error}`)
    );
  }
}
