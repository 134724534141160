import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ApiResponseEntity } from './api-response.model';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  constructor(private http: HttpClient, private utils: UtilsService) {
    // this.url = `http://localhost:8080`;
  }

  url = environment.backendUrl; // `http://localhost:8080`; // `https://crmt.ishayoga.live`;
  useLive = false;
  country: string;
  loadingChange = new Subject<boolean>();

  api(endpoint: string, values: object = {}, auth: boolean = true): Observable<any> {
    const cmds = endpoint.split('.');
    const params = { action: cmds[1] };

    if (auth === undefined) {
      auth = true;
    }

    const path = auth ? '_api/' : '';

    _.forEach(values, (v, k) => {
      if (v === '_today_') {
        params[k] = this.utils.now().format('YYYYMMDD');
      } else {
        params[k] = values[k];
      }
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      withCredentials: true
    };

    this.loadingChange.next(true);
    return this.http.post(`${this.url}/${path}${cmds[0]}`, new HttpParams({ fromObject: params }), httpOptions).pipe(
      finalize(() => {
        this.loadingChange.next(false);
      })
    );
  }

  getCountry() {
    return new Observable<string>(observer => {
      if (this.country) {
        return observer.next(this.country);
      }

      this.http
        .get('https://asia-east2-ishacrmserver.cloudfunctions.net/geo-redirect/getCountry', { responseType: 'text' })
        .subscribe(
          country => {
            this.country = country;
            observer.next(this.country);
          },
          () => {
            observer.error();
          }
        );
    });
  }

  gformSubmit(scriptId: string, params: any) {
    const formUrl = 'https://script.google.com/macros/s/' + scriptId + '/exec';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    // console.log(params);

    this.loadingChange.next(true);
    return this.http.post(formUrl, new HttpParams({ fromObject: params }), httpOptions).pipe(
      finalize(() => {
        this.loadingChange.next(false);
      })
    );
  }

  public hasLoadedFromCache<T>(behaviourSubject: BehaviorSubject<ApiResponseEntity<T>>, useCache: boolean): boolean {
    const cache = behaviourSubject.getValue();
    const hasCacheValue = Object.keys((cache && cache.object) || {}).length > 0;
    if (useCache && hasCacheValue) {
      behaviourSubject.next(cache);
      return true;
    }

    return false;
  }
}
