import {Component} from '@angular/core';

@Component({
  selector: 'app-sages-of-india-part-1',
  templateUrl: './sages-of-india-part-1.component.html',
  styleUrls: ['../../register.component.scss']
})
export class SagesOfIndiaPart1Component {
  constructor() {
  }
}
