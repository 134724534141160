import {sortBy} from 'lodash';
import * as _ from 'lodash';
import {Component, OnInit} from '@angular/core';
import {finalize} from 'rxjs/operators';

import {BackendService} from '../../../shared/backend.service';
import {ModalService} from '../../../shared/modal';
import {ApiResponseModel} from '../../../shared/api-response.model';
import {AuthService} from '../../../auth/auth.service';
import {User} from '../../../auth/user.model';

@Component({
  selector: 'app-register-funday',
  templateUrl: './register-funday.html',
  styleUrls: ['./register-funday.scss']
})
export class RegisterFundayComponent implements OnInit {

  constructor(private backend: BackendService, private auth: AuthService, private modal: ModalService) {
    this.user = this.auth.getUser();
  }
  user: User;
  isInit: boolean;
  selectedSession: any;
  registeredSession: any;
  isAlreadyRegistered: boolean;
  sessions = [
    {
      center: 'Melbourne',
      date: 'Sat, 5th Jun',
      time: '8:00am AEDT',
      programId: 5517,
      submitting: false,
      registered: false,
      declarationLink: 'https://forms.gle/DQHxqZjG6jreLaEVA',
      venue: {
        name: 'Isha Yoga Centre, Melbourne',
        address: '93 Hampshire Rd, Sunshine VIC 3020',
        mapLink: 'https://goo.gl/maps/ZuokHf1V68AVcBUc6',
      },
      country: 'AU',
      regClosed: false
    },
  ];
  modalId = 'funday-session-select';
  bodyText: string;

  ngOnInit() {
    const programIds = _.map(this.sessions, 'programId');
    this.backend.api('portal.getRegisteredPrograms', {programIds})
      .pipe(
        finalize(() => {
          this.backend.getCountry().subscribe(
            (country: string) => {
              this.sessions = sortBy(this.sessions, [(c) => c.country !== country]);
              this.isInit = true;
            });
        })
      )
      .subscribe(
        (rsp: ApiResponseModel) => {
          if (rsp.statusCode === 'SUCCESS') {
            this.isAlreadyRegistered = rsp.object && rsp.object.length > 0;
            _.forEach(rsp.object, (p) => {
              const registeredProgram = this.isRegisteredSession(p);
              if (registeredProgram) {
                this.registeredSession = registeredProgram;
              }
            });
          }
        }
      );
  }

  register(session) {
    session.submitting = true;
    this.backend.api('portal.register', {programId: session.programId, memberId: this.user.memberId})
      .pipe(
        finalize(() => {
          session.submitting = false;
        })
      )
      .subscribe(
        (rsp: ApiResponseModel) => {
          if (rsp.statusCode === 'SUCCESS') {
            this.registeredSession = this.isRegisteredSession(rsp.object);
            this.closeModal();
          }
        }
      );
  }

  isRegisteredSession(p) {
    const programs = _.filter(this.sessions, (sess) => {
      return sess.programId === p.programId;
    });
    return programs[0] || null;
  }

  openModal(session) {
    this.selectedSession = session;
    this.modal.open(this.modalId);
  }

  closeModal() {
    this.modal.close(this.modalId);
  }

}
