import {Component, Input} from '@angular/core';
import {Program} from '../../program';

@Component({
  selector: 'app-bond-of-grace',
  templateUrl: './bond-of-grace.component.html',
  styles: []
})
export class BondOfGraceComponent {
  @Input() program: Program;
  constructor() {
  }
}
