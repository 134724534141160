import {Component, Input} from '@angular/core';
import {Program} from '../../program';

@Component({
  selector: 'app-sadhanapada-webinar',
  templateUrl: './sadhanapada-webinar.component.html',
  styleUrls: ['../../register.component.scss']
})
export class SadhanapadaWebinarComponent {
  @Input() program: Program;
  constructor() {
  }
}
