import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { User } from '../../auth/user.model';
import { BackendService } from '../../shared/backend.service';
import { NotificationService } from '../../shared/notification/notification.service';

@Component({
  selector: 'app-check-meditator',
  templateUrl: './check-meditator.component.html',
  styleUrls: ['./check-meditator.component.scss']
})
export class CheckMeditatorComponent implements OnInit {
  user: User;
  isSubmitting: boolean;
  submitted: boolean;
  alreadySubmitted: boolean;

  constructor(
    private backendService: BackendService,
    private authService: AuthService,
    private ntfService: NotificationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.user = this.authService.getUser();
    if (this.user.isMeditator) {
      this.router.navigate(['']);
    }
    this.alreadySubmitted = localStorage.getItem(`${this.user.memberId}-ie-verify-submitted`) === 'true';
  }

  onSubmit(f: NgForm) {
    const params = {
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
      memberId: this.user.memberId,
      phoneNumber: f.value.phoneNumber,
      ieMonthYear: f.value.ieProgramDate,
      ieCenter: f.value.ieProgramLocation,
      ieTeacher: f.value.ieProgramTeacher
    };

    this.isSubmitting = true;
    this.backendService
      .gformSubmit('AKfycbyUnx3NcVuDXffa7EWQzrSnxFsWYgFk7w8IhnHTVSq9eIazo-o', params)
      .pipe(
        finalize(() => {
          this.isSubmitting = false;
        })
      )
      .subscribe(() => {
        this.ntfService.info('Form Submitted Successfully!');
        this.submitted = true;
        localStorage.setItem(`${this.user.memberId}-ie-verify-submitted`, 'true');
        this.alreadySubmitted = true;
      });
  }
}
