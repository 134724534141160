import { Component, Input, OnInit } from '@angular/core';
import { Program } from '../../program';

@Component({
  selector: 'app-my-ie-story-sharing-workshop',
  templateUrl: './my-ie-story-sharing-workshop.component.html'
})
export class MyIEStorySharingWorkshopComponent implements OnInit {
  @Input() program: Program;
  constructor() { }

  ngOnInit() {
  }

}
