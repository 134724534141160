import { Component, Input, OnInit } from '@angular/core';
import { Program } from '../../program';

@Component({
  selector: 'app-being-a-volunteer',
  templateUrl: './being-a-volunteer.component.html',
  styleUrls: ['./being-a-volunteer.component.scss']
})
export class BeingAVolunteerComponent implements OnInit {
  @Input() program: Program;
  constructor() { }

  ngOnInit() {
  }

}
