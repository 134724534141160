import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { sortBy } from 'lodash';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { User } from '../../../auth/user.model';
import { ApiResponseModel } from '../../../shared/api-response.model';
import { BackendService } from '../../../shared/backend.service';
import { ModalService } from '../../../shared/modal';


@Component({
  selector: 'app-register-msr',
  templateUrl: './register-msr.html',
  styleUrls: ['./register-msr.scss']
})
export class RegisterMsrComponent implements OnInit {

  constructor(private backend: BackendService, private auth: AuthService, private modal: ModalService) {
    this.user = this.auth.getUser();
  }
  user: User;
  isInit: boolean;
  selectedSession: any;
  registeredSession: any;
  isAlreadyRegistered: boolean;
  date = 'Fri, 8th Mar';
  sessions = [
    {
      center: 'Melbourne',
      time: '7:00pm AEDT',
      programId: 8015,
      regClosed: false,
      venue: {
        name: 'Shri Shiva Vishnu Temple',
        address: 'Peacock Room, 52 Boundary Rd, Carrum Downs VIC 3201',
        mapLink: 'https://maps.app.goo.gl/KKiYFV96FrbmDV2g7',
      },
      country: 'AU',
    },
    {
      center: 'Sydney',
      time: '7:00pm AEDT',
      programId: 8020,
      regClosed: false,
      venue: {
        name: 'Hornsby War Memorial Hall',
        address: '2 High St, Hornsby NSW 2077',
        mapLink: 'https://g.co/kgs/GkUo5zp',
      },
      country: 'AU',
    },
    {
      center: 'Brisbane',
      time: '7:00pm AEST',
      programId: 7969,
      regClosed: false,
      venue: {
        name: 'Bodhi Chan Meditation Centre',
        address: '223 Dennis Rd, Springwood QLD 4127',
        mapLink: 'https://maps.app.goo.gl/owohvAhLYLW53pif6',
      },
      country: 'AU',
    },
    {
      center: 'Canberra',
      time: '7:00pm AEDT',
      programId: 8017,
      regClosed: false,
      venue: {
        name: 'Volunteer\'s Residence',
        address: '11 Jamrozik St, Denman Prospect ACT 2611',
        mapLink: 'https://maps.app.goo.gl/oqDKeb9sLyGzpjbi6',
      },
      country: 'AU',
    },
    {
      center: 'Perth',
      time: '7:00pm AWST',
      programId: 8016,
      regClosed: false,
      venue: {
        name: 'Indian Community Centre',
        address: '12 Whyalla St, Willetton WA 6155',
        mapLink: 'https://maps.app.goo.gl/gCTD8216dfW4317y5',
      },
      country: 'AU',
    },
    {
      center: 'Adelaide',
      time: '7:00pm ACDT',
      programId: 8025,
      regClosed: false,
      venue: {
        name: 'Imagination Kidz',
        address: '264, Richmond road, Marleston SA 5033',
        mapLink: 'https://maps.app.goo.gl/2PzJsJSdD39G8JnZ9',
      },
      country: 'AU',
    },
    {
      center: 'Singapore',
      time: '7:00pm SGT',
      programId: 8012,
      regClosed: false,
      venue: {
        name: 'Isha Yoga Centre SG',
        address: '5008 Ang Mo Kio Ave 5, #05-16 Techplace II, S569874',
        mapLink: 'https://isha.to/s/iycm',
      },
      country: 'SG',
    },
    {
      center: 'Malaysia',
      time: '7:00pm MYT',
      programId: 8013,
      regClosed: false,
      venue: {
        name: 'Isha Yoga Centre Malaysia',
        address: 'Suite G.03, Ground Floor Block-B, Dataran Hamodal, Jalan 13/4, Section 13, 46200 Petaling Jaya, Selangor, Malaysia',
        mapLink: 'https://g.page/ishamalaysia',
      },
      country: 'MY',
    },
    {
      center: 'Hong Kong',
      time: '7:00pm HKT',
      programId: 8023,
      regClosed: false,
      venue: {
        name: 'Isha Yoga Centre HK',
        address: 'Room 601 Block C Seaview Estate 2-8 Watson Road Tin Hau Hong Kong',
        mapLink: 'https://goo.gl/maps/k7a2i8DdVmsHqBfdA',
      },
      country: 'HK',
    },
    {
      center: 'Indonesia',
      time: '6:00pm WIB',
      programId: 8018,
      regClosed: false,
      venue: {
        name: 'Yoga Systems Hatha Yoga School',
        address: '15, Tanah Teduh, Jl. Jati Padang Raya, Gang Wahid 35, Pasar Minggu, Jakarta Selatan. 12540',
        mapLink: 'https://maps.app.goo.gl/59yog22FTij6yBi36',
      },
      country: 'ID',
    },
    {
      center: 'Philippines',
      time: '7:00pm PHT',
      programId: 8019,
      regClosed: false,
      venue: {
        name: 'Volunteer\'s Residence',
        address: '382 Cagayan Valley Rd, Old Sta Rita, San Miguel Bulacan',
        mapLink: 'https://maps.app.goo.gl/6tbEuNNs5gfZZJ537',
      },
      country: 'PH',
    },
    {
      center: 'Japan',
      time: '7:00pm JST',
      programId: 8024,
      regClosed: false,
      venue: {
        name: 'Kajiwara Ginza Building',
        address: '〒114-0004 東京都北区堀船３丁目２４－４梶原銀座ビル3階',
        mapLink: 'https://maps.app.goo.gl/snbS3gcgiJZw6mYU9',
      },
      country: 'JP',
    },
    {
      center: 'Thailand',
      time: '6:00pm ICT',
      programId: 8022,
      regClosed: false,
      venue: {
        name: 'Slowcombo, Energy Space',
        address: '2A, 2nd Floor, 126 ซ. จุฬาลงกรณ์ 50 Wang Mai (Samyan Rama IV between soi chula 48 and soi chula 50), Bangkok, 10330',
        mapLink: 'https://maps.app.goo.gl/13fFhXZaz9HfpvPo8',
      },
      country: 'TH',
    },
    {
      center: 'Auckland',
      time: '7:00pm NZDT ',
      programId: 8021,
      regClosed: false,
      venue: {
        name: 'Volunteer\'s Residence',
        address: '7 Lavey Road, Flatbush Auckland 2019',
        mapLink: 'https://maps.app.goo.gl/Sq4eFaLy6UbRwDiU6',
      },
      country: 'NZ',
    },
  ];
  modalId = 'msr-session-select';

  ngOnInit() {
    const programIds = _.map(this.sessions, 'programId');

    this.backend.api('program.get', {programIds, getStats: true})
      .subscribe((rsp: ApiResponseModel) => {
        _.forEach(rsp.object, (p) => {
          this.getSession(p).regClosed = p.isRegistrationLimitReached;
        });

        this.backend.api('portal.getRegisteredPrograms', {programIds})
          .pipe(
            finalize(() => {
              this.backend.getCountry().subscribe(
                (country: string) => {
                  this.sessions = sortBy(this.sessions, [(c) => c.country !== country]);
                  this.isInit = true;
                });
            })
          )
          .subscribe(
            (rsp2: ApiResponseModel) => {
              this.isAlreadyRegistered = rsp2.object && rsp2.object.length > 0;
              _.forEach(rsp2.object, (p) => {
                const registeredProgram = this.getSession(p);
                if (registeredProgram) {
                  this.registeredSession = registeredProgram;
                }
              });
            }
          );
      });
  }

  register(session) {
    session.submitting = true;
    this.backend.api('portal.register', {programId: session.programId, memberId: this.user.memberId})
      .pipe(
        finalize(() => {
          session.submitting = false;
        })
      )
      .subscribe(
        (rsp: ApiResponseModel) => {
          if (rsp.statusCode === 'SUCCESS') {
            this.registeredSession = this.getSession(rsp.object);
            this.closeModal();
          }
        }
      );
  }

  getSession(p) {
    return _.find(this.sessions, (sess) => sess.programId === p.programId);
  }

  openModal(session) {
    this.selectedSession = session;
    this.modal.open(this.modalId);
  }

  closeModal() {
    this.modal.close(this.modalId);
  }

}
