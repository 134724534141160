import { Component, Input, OnInit } from '@angular/core';
import { Program } from '../../program';

@Component({
  selector: 'app-intro-to-bhava-spandana',
  templateUrl: './intro-to-bhava-spandana.component.html',
  styleUrls: ['./intro-to-bhava-spandana.component.scss']
})
export class IntroToBhavaSpandanaComponent implements OnInit {
  @Input() program: Program;
  constructor() { }

  ngOnInit() {
  }
}
