export type MultiSelectOption = { id: string; label: string };

const buildOptions = <T>(obj: T) => {
  return Object.keys(obj).map(
    key =>
      ({
        id: key,
        label: obj[key]
      } as MultiSelectOption)
  );
};

export const ProgramsCompletedLabels = {
  innerEngineering: 'Inner Engineering (Shambhavi)',
  bhavaSpandana: 'Bhava Spandana',
  shoonya: 'Shoonya',
  samyama: 'Samyama',
  other: 'Other'
};
export const ProgramsCompletedOptions = buildOptions(ProgramsCompletedLabels);

export const ProfessionalITSkillsLabels = {
  webDesign: 'Web Design',
  dbManagement: 'DB Management',
  graphicDesign: 'Graphic Design',
  analytics: 'Analytics',
  softwareDevelopmentPrograms: 'Software Development Programs',
  other: 'Other'
};
export const ProfessionalITSkillsOptions = buildOptions(ProfessionalITSkillsLabels);

export const ProfessionalDMSkillsLabels = {
  facebook: 'DM Facebook',
  youtube: 'DM Youtube',
  x: 'DM X (Formerly Twitter)',
  googleAds: 'Google Ads',
  googleAnalytics: 'Google Analytics',
  emailMarketing: 'Email Marketing',
  other: 'Other'
};
export const ProfessionalDMSkillsOptions = buildOptions(ProfessionalDMSkillsLabels);

export const ProfessionalOtherSkillsLabels = {
  photography: 'Photography',
  videoRecording: 'Video Recording',
  videoEditing: 'Video Editing',
  creativeWriting: 'Creative Writing',
  decoration: 'Decoration',
  legal: 'Legal',
  av: 'AV',
  digitalContent: 'Digital Content',
  entertainmentAndMedia: 'Entertainment & Media',
  realEstate: 'Real Estate',
  other: 'Other'
};
export const ProfessionalOtherSkillsOptions = buildOptions(ProfessionalOtherSkillsLabels);

export const ResourcesLabels = {
  materialStorage: 'Material Storage (provide space in your house to store Isha materials)',
  transportation: `Transportation (provide transport for ishangas, ashram residents from airport etc.)`,
  hosting: 'Hosting (offering space in your home for ishangas, ashram residents to stay)',
  cooking: 'Cooking (provide food for small groups of around approx 5-10 volunteers/ishangas)',
  other: 'Other'
};
export const ResourcesOptions = buildOptions(ResourcesLabels);

export const SocialMediaInfluencersLabels = {
  x: 'X (Formerly Twitter)',
  instagram: 'Instagram',
  facebook: 'Facebook',
  tiktol: 'TikTok',
  youtube: 'Youtube',
  other: 'Other'
};
export const SocialMediaInfluencersOptions = buildOptions(SocialMediaInfluencersLabels);

export const SydneyVolunteeringDays = {
  days5to10: '5 to 10 days',
  Days10to20: '10 to 20 days',
  moreThan20Days: 'More than 20 days',
  other: 'Other'
};
export const SydneyVolunteeringDaysOptions = buildOptions(SydneyVolunteeringDays);

export const VolunteeringAvailability = {
  nonParticipantProgramVolunteering:
    'I am volunteering and not registered as a participant for the program and will available to volunteer from today and in person in Sydney on 18 to 20 Jan (full days).',
  participantProgramVolunteering:
    'I am participating in the program on 20 Jan. I will be available to volunteer from today. I will also be able to volunteer in person in Sydney on 18 & 19 January (full days).',
  participantPreProgramVolunteering:
    'I am participating in the program on 20 Jan. I will be available to volunteer from today. I will NOT be available to volunteer in person in Sydney on 18 & 19 January (full days).'
};
export const VolunteeringAvailabilityOptions = buildOptions(VolunteeringAvailability);
