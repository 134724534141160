import {Component} from '@angular/core';

@Component({
  selector: 'app-msr-meet',
  templateUrl: './msr-meet.component.html',
  styleUrls: ['../../register.component.scss']
})
export class MsrMeetComponent {
  constructor() {
  }
}
