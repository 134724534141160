import { Component, Input } from '@angular/core';
import { Program } from '../../program';

@Component({
  selector: 'app-sgex-trivia',
  templateUrl: './sgex-trivia.component.html',
  styleUrls: []
})
export class SgexTriviaComponent {
  @Input() program: Program;
  constructor() {}
}
