import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { sortBy } from 'lodash';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../../../../auth/auth.service';
import { User } from '../../../../../auth/user.model';
import { ApiResponseModel } from '../../../../../shared/api-response.model';
import { BackendService } from '../../../../../shared/backend.service';
import { ModalService } from '../../../../../shared/modal';

@Component({
  selector: 'app-smm-pc',
  templateUrl: './volunteer-meet-festive-celebration.component.html',
  styleUrls: ['./volunteer-meet-festive-celebration.component.scss']
})
export class VolunteerMeetFestiveCelebrationComponent implements OnInit {
  programIds: number[];
  constructor(private backend: BackendService, private auth: AuthService, private modal: ModalService,
              private route: ActivatedRoute) {
    this.programIds = _.map(this.route.snapshot.params.programIds.split('-'), (p) =>  parseInt(p, 10) );
    this.user = this.auth.getUser();
  }
  user: User;
  isInit: boolean;
  selectedSession: any;
  registeredSession: any;
  isAlreadyRegistered: boolean;
  sessions = [
    // {
    //   center: 'Sydney',
    //   programId: 7610,
    //   date: '11 Nov',
    //   time: '10.30am - 12.30pm',
    //   addInfo: 'followed by lunch',
    //   registrationLimitReached: false,
    //   submitting: false,
    //   venue: {
    //     name: 'Galston Community Centre',
    //     address: '37 Arcadia Rd, Galston NSW 2159',
    //     mapLink: 'https://goo.gl/maps/GcUayEgPzhfk93966'
    //   },
    //   country: 'AU'
    // },
    {
      center: 'Melbourne',
      programId: 7609,
      date: '18 Nov',
      time: '5.30pm - 7.30pm',
      addInfo: 'followed by dinner',
      registrationLimitReached: false,
      submitting: false,
      venue: {
        name: 'Isha Yoga Centre - Melbourne',
        address: '93 Hampshire Rd, Sunshine VIC 3020',
        mapLink: 'https://goo.gl/maps/aZJA2yqzKEVc4pyz9'
      },
      country: 'AU'
    },
    // {
    //   center: 'Brisbane',
    //   programId: 7620,
    //   date: '11 Nov',
    //   time: '10.30am - 12.30pm',
    //   addInfo: 'followed by lunch',
    //   registrationLimitReached: false,
    //   submitting: false,
    //   venue: {
    //     name: 'Clayfield Anglican Church hall',
    //     address: '23 Victoria St, Clayfield 4011',
    //     mapLink: 'https://maps.app.goo.gl/oHKmgJiVooWUqTmN7'
    //   },
    //   country: 'AU'
    // },
    {
      center: 'Perth',
      programId: 7619,
      date: '18 Nov',
      time: '10.00am - 12.00pm',
      addInfo: 'followed by lunch',
      registrationLimitReached: false,
      submitting: false,
      venue: {
        name: 'WA Croatian Community Centre',
        address: 'Alfred Rd, North Fremantle WA 6159',
        mapLink: 'https://goo.gl/maps/8bWkzPkbeGr'
      },
      country: 'AU'
    },
    // {
    //   center: 'Canberra',
    //   programId: 7637,
    //   date: '14 Nov',
    //   time: '06.00pm - 08.00pm',
    //   addInfo: 'followed by dinner',
    //   registrationLimitReached: false,
    //   submitting: false,
    //   venue: {
    //     name: '24 Care Community Hall',
    //     address: 'Unit 6/53 Dundas CT, Phillip, ACT-2606',
    //     mapLink: 'https://maps.app.goo.gl/oA9Jyi2J2Gzu1nF99'
    //   },
    //   country: 'AU'
    // },
    {
      center: 'Adelaide',
      programId: 7639,
      date: '19 Nov',
      time: '04.00pm - 08.00pm',
      addInfo: 'practice correction followed by Festive Volunteers Meet',
      registrationLimitReached: false,
      submitting: false,
      venue: {
        name: 'Henley and Grange Community Centre',
        address: '196A Military Rd, Henley Beach SA 5022',
        mapLink: 'https://maps.app.goo.gl/yvHGctEhhXqx7trHA'
      },
      country: 'AU'
    }
  ];
  isRegistrationRequired = true;

  ngOnInit() {
    this.sessions = _.filter(this.sessions, (s) => _.includes(this.programIds, s.programId));

    this.backend.api('program.get', {programIds: this.programIds, getStats: true})
      .subscribe((rsp: ApiResponseModel) => {
        _.forEach(rsp.object, (p) => {
          this.getSession(p).registrationLimitReached = p.isRegistrationLimitReached;
        });

        this.backend.api('portal.getRegisteredPrograms', {programIds: this.programIds})
          .pipe(
            finalize(() => {
              this.backend.getCountry().subscribe(
                (country: string) => {
                  this.sessions = sortBy(this.sessions, [(c) => c.country !== country]);
                  this.isInit = true;
                });
            })
          )
          .subscribe(
            (rsp2: ApiResponseModel) => {
              this.isAlreadyRegistered = rsp2.object && rsp2.object.length > 0;
              _.forEach(rsp2.object, (p) => {
                const registeredProgram = this.getSession(p);
                if (registeredProgram) {
                  this.registeredSession = registeredProgram;
                }
              });
            }
          );
     });
 }

  register(session) {
    session.submitting = true;
    this.backend.api('portal.register', {programId: session.programId, memberId: this.user.memberId})
      .pipe(
        finalize(() => {
          session.submitting = false;
        })
      )
      .subscribe(
        (rsp: ApiResponseModel) => {
          if (rsp.statusCode === 'SUCCESS') {
            this.registeredSession = this.getSession(rsp.object);
          }
        }
      );
  }

  getSession(p) {
    return _.find(this.sessions, (sess) => sess.programId === p.programId);
  }
}
