import {Component} from '@angular/core';

@Component({
  selector: 'app-new-isha-offerings',
  templateUrl: './new-isha-offerings.component.html',
  styleUrls: ['../../register.component.scss']
})
export class NewIshaOfferingsComponent {
  constructor() {
  }
}
