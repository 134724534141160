import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExternalLinkIconComponent } from './external-link-icon/external-link-icon.component';

@NgModule({
  declarations: [ExternalLinkIconComponent],
  imports: [CommonModule],
  exports: [ExternalLinkIconComponent]
})
export class IconsModule {}
