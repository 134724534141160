import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { sortBy } from 'lodash';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { User } from '../../../auth/user.model';
import { ApiResponseModel } from '../../../shared/api-response.model';
import { BackendService } from '../../../shared/backend.service';
import { ModalService } from '../../../shared/modal';
import * as dayjs from 'dayjs';
import { UtilsService } from '../../../shared/utils.service';

class Venue {
  name: string;
  address: string;
  mapLink: string;
}

class Session {
  date: string;
  time: string;
  duration: string;
  programId: number;
  registrationLimitReached: boolean;
  center: string;
  venue: Venue;
  country: string;
  language: string;
  submitting?: boolean;
  registered?: boolean;

}

@Component({
  selector: 'app-smm-pc',
  templateUrl: './register-smm-gp.component.html',
  styleUrls: ['./register-smm-gp.component.scss']
})
export class RegisterSmmGpComponent implements OnInit {
  programIds: number[];
  constructor(private backend: BackendService, private auth: AuthService, private modal: ModalService,
              private route: ActivatedRoute, private util: UtilsService) {
    this.programIds = _.map(this.route.snapshot.params.programIds.split('-'), (p) =>  parseInt(p, 10) );
    this.user = this.auth.getUser();
  }
  user: User;
  isInit: boolean;
  selectedSession: any;
  registeredSession: any;
  isAlreadyRegistered: boolean;
  sessions: Session[] = [];
  modalId = 'practice-correction-session-select';

  ngOnInit() {
    this.sessions = _.filter(this.sessions, (s) => _.includes(this.programIds, s.programId));
    this.backend.api('program.get', {programIds: this.programIds, getStats: true})
      .subscribe((rsp: ApiResponseModel) => {
        if (rsp.statusCode !== 'SUCCESS') {
          return;
        }

        if (rsp.object.length === 0) {
          return;
        }

        _.forEach(rsp.object, (p) => {
          const start = p.sessionTimings[0].start;
          this.sessions.push({
            center: p.groupProp.displayName.replace('Isha ', ''),
            venue: {
              name: p.venueProp.displayName,
              address: p.venueProp.address,
              mapLink: p.venueProp.mapLink,
            },
            country: p.venueProp.country,
            language: p.language,
            submitting: false,
            registered: false,
            programId: p.programId,
            date: dayjs.unix(start).format('dddd, Do MMM'),
            time: dayjs.unix(start).format('h:mma ') + this.util.tzAbbr(start),
            duration: this.util.toDuration(p.sessionTimings[0].duration),
            registrationLimitReached: p.isRegistrationLimitReached,
          });

          const sess = this.getSession(p);

          sess.programId = p.programId;
          sess.date = dayjs.unix(start).format('dddd, Do MMM');
          sess.time = dayjs.unix(start).format('h:mma ') + this.util.tzAbbr(start);
          sess.duration = this.util.toDuration(p.sessionTimings[0].duration);
          sess.registrationLimitReached = p.isRegistrationLimitReached;
        });

        this.backend.api('portal.getRegisteredPrograms', {programIds: this.programIds})
          .pipe(
            finalize(() => {
              this.backend.getCountry().subscribe(
                (country: string) => {
                  this.sessions = sortBy(this.sessions, [(c) => c.country !== country]);
                  this.isInit = true;
                });
            })
          )
          .subscribe(
            (rsp2: ApiResponseModel) => {
              this.isAlreadyRegistered = rsp2.object && rsp2.object.length > 0;
              _.forEach(rsp2.object, (p) => {
                const registeredProgram = this.getSession(p);
                if (registeredProgram) {
                  this.registeredSession = registeredProgram;
                }
              });
            }
          );
      });


  }

  register(session) {
    session.submitting = true;
    this.backend.api('portal.register', {programId: session.programId, memberId: this.user.memberId})
      .pipe(
        finalize(() => {
          session.submitting = false;
        })
      )
      .subscribe(
        (rsp: ApiResponseModel) => {
          if (rsp.statusCode === 'SUCCESS') {
            this.registeredSession = this.getSession(rsp.object);
            this.closeModal();
          }
        }
      );
  }

  getSession(p) {
    return _.find(this.sessions, (sess) => sess.programId === p.programId);
  }

  openModal(session) {
    this.selectedSession = session;
    this.modal.open(this.modalId);
  }

  closeModal() {
    this.modal.close(this.modalId);
  }

}
