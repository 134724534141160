import {Component} from '@angular/core';

@Component({
  selector: 'app-isha-covid-action',
  templateUrl: './isha-covid-action.component.html',
  styleUrls: ['../../register.component.scss']
})
export class IshaCovidActionComponent {
  constructor() {
  }
}
