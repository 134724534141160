import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/auth/user.model';
import { ScrollService } from 'src/app/shared/services/scroll/scroll.service';
import { Options } from '../../models/volunteer-status.entity';
import { SiaService, VolunteeringStatus } from '../../sia.service';
import { VolunteeringAvailabilityOptions } from '../registration/volunteer-registration.constants';

@Component({
  selector: 'app-volunteer-thank-you',
  templateUrl: './volunteer-thank-you.component.html',
  styleUrls: ['./volunteer-thank-you.component.scss']
})
export class VolunteerThankYouComponent implements OnInit {
  params: Params;

  amount!: number;
  ccy!: string;
  txnId!: string;
  status!: string;
  purchase!: string;
  summary!: string;
  success!: boolean;
  pending!: boolean;
  processing!: boolean;
  error!: boolean;
  errorMsg!: string;
  cancelled!: boolean;

  registeredAndContributed = false;
  completedVolAvail = false;

  availabilityFG = new FormGroup({
    volunteeringAvailability: new FormControl(undefined, [Validators.required])
  });

  volunteeringAvailabilityOptions = VolunteeringAvailabilityOptions;

  user: User = this.authService.user;
  memberId: number = this.user.memberId;
  options: Options;

  loading = true;
  submitting$ = this.siaService.submitting$;
  submissionError$ = this.siaService.submissionError$;

  constructor(
    private route: ActivatedRoute,
    private siaService: SiaService,
    private authService: AuthService,
    private scrollService: ScrollService
  ) {}

  ngOnInit() {
    this.siaService.getVolunteeringStatus(this.authService.user.memberId).subscribe((status: VolunteeringStatus) => {
      this.completedVolAvail = status.hasCompletedVolunteerAvailability;
      this.options = status.options;
      this.loading = false;
      if (status.isRegistered && status.hasContributed) {
        this.registeredAndContributed = true;
      }
    });

    this.route.queryParams.subscribe((params: Params) => {
      this.params = params;

      this.amount = +this.params['amount'];
      this.ccy = this.params['ccy'];
      this.txnId = this.params['transactionId'];
      this.status = this.params['status'];

      switch (this.status) {
        case 'COMPLETED':
          this.summary = 'Completed';
          this.success = true;
          break;
        case 'PENDING':
          this.summary = 'Payment Pending';
          this.pending = true;
          break;
        case 'INPROGRESS':
          this.summary = 'Payment Processing';
          this.processing = true;
          break;
        case 'ERROR':
          this.summary = 'Payment Error';
          this.error = true;
          this.errorMsg = this.params['error'];
          break;
        case 'CANCELLED':
          this.summary = 'Cancelled';
          this.cancelled = true;
          break;
      }
    });
  }

  submitVolunteeringAvailability(): void {
    if (this.availabilityFG.invalid) {
      this.availabilityFG.markAllAsTouched();
      const scrollOffset = 56;
      this.scrollService.scrollToFirstInvalidFormControl(this.availabilityFG, scrollOffset);
      return;
    }

    this.siaService.submitVolAvailability(
      this.memberId,
      this.options,
      this.availabilityFG.get('volunteeringAvailability').value,
      () => (this.completedVolAvail = true)
    );
  }
}
