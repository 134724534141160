import {Component} from '@angular/core';

@Component({
  selector: 'app-karma-book-sea',
  templateUrl: './karma-book-sea.component.html',
  styleUrls: ['../../register.component.scss']
})
export class KarmaBookSeaComponent {
  constructor() {
  }
}
