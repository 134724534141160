import {Component, Input} from '@angular/core';
import {Program} from '../../program';

@Component({
  selector: 'app-sia-going-all-out',
  templateUrl: './sia-going-all-out.component.html',
  styleUrls: ['../../register.component.scss']
})
export class SiaGoingAllOutComponent {
  @Input() program: Program;
  constructor() {
  }
}
