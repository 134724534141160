import {Component, Input} from '@angular/core';
import {Program} from '../../program';

@Component({
  selector: 'app-sanghamitra',
  templateUrl: './sanghamitra.component.html',
  styles: []
})
export class SanghamitraComponent {
  @Input() program: Program;
  constructor() {
  }
}
