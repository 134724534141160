import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { VolunteerRegistrationEntity } from 'src/app/meditator/register/sia-2024/models/volunteer-registration.entity';
import { VolunteerStatusEntity } from 'src/app/meditator/register/sia-2024/models/volunteer-status.entity';
import { ApiResponseEntity, ApiResponseModel } from '../../api-response.model';
import { BackendService } from '../../backend.service';
import { FormUtil } from '../../utils/form-util';
import { ContributionPayload, Member } from './member.entity';
import { VolunteerProfileEntity } from './volunteer-profile.entity';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  private client = 'isha';
  private feature = 'member';

  private member = new BehaviorSubject<Member>({} as Member);
  public member$ = this.member.asObservable();

  private volunteerRegistration = new BehaviorSubject<Member>({} as Member);
  public volunteerRegistration$ = this.volunteerRegistration.asObservable();

  redirecting = new BehaviorSubject<boolean>(false);
  redirecting$ = this.redirecting.asObservable();

  constructor(private backend: BackendService) {}

  public loadMember(memberId: number) {
    const params = {
      memberId
    };
    this.backend.api(`${this.feature}.detailedInfo`, params).subscribe(
      (response: ApiResponseEntity<Member>) => {
        if (response.statusCode === 'SUCCESS') {
          this.member.next(response.object);
        } else {
          console.log(`Error: ${response.userFriendlyMessage}`);
        }
      },
      error => console.log(`Error: ${error}`)
    );
  }

  public registerAsVolunteer(member: VolunteerRegistrationEntity): Observable<ApiResponseModel> {
    return this.backend.api(`${this.feature}.registerAsVolunteer`, member);
  }

  public getVolunteerStatus(params: any): Observable<ApiResponseEntity<VolunteerStatusEntity>> {
    return this.backend.api(`${this.feature}.getVolunteerStatus`, params);
  }

  payVolunteerContribution(programId: number, memberId: number, amt: number, successUrl: string) {
    this.redirecting.next(true);

    let form = document.createElement('form');
    form.method = 'POST';
    form.action = `${environment.backendUrl}/${this.feature}`;

    const payload: ContributionPayload = {
      programId,
      amt,
      action: 'payVolunteerContribution',
      memberId,
      successUrl: window.location.protocol + '//' + window.location.host + successUrl
    };

    for (let key in payload) {
      FormUtil.appendChildToForm(form, key, payload[key]);
    }

    FormUtil.submitForm(form);
  }

  public registerExtendedProfile(memberId: number, member: VolunteerProfileEntity): Observable<ApiResponseModel> {
    return this.backend.api(`${this.feature}.setExtendedProfile`, member);
  }

  public registerExtendedProfileTs(memberId: number): Observable<ApiResponseModel> {
    return this.backend.api(`${this.feature}.setExtendedProfileTs`, { memberId });
  }

  public registerProgram(memberId: number, programId: number): Observable<ApiResponseModel> {
    return this.backend.api(`portal.register`, {programId, memberId});
  }
}
