import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { BackendService } from '../../../shared/backend.service';
import { ModalService } from '../../../shared/modal';
import { ApiResponseModel } from '../../../shared/api-response.model';
import { forEach, map } from 'lodash';
import * as dayjs from 'dayjs';

class Venue {
  name: string;
  address: string;
  mapLink: string;
}

class Session {
  center: string;
  venue: Venue;
  country: string;
}

@Component({
  selector: 'app-smm-satsang',
  templateUrl: './register-satsang.component.html',
  styleUrls: ['./register-satsang.component.scss']
})
export class RegisterSatsangComponent implements OnInit {
  programIds: number[];
  date: string;

  constructor(private backend: BackendService, private auth: AuthService, private modal: ModalService,
              private route: ActivatedRoute) {
    this.programIds = map(this.route.snapshot.params.programIds.split('-'), (p) => parseInt(p, 10));
  }

  isInit: boolean;
  sessions: Session[] = [];

  ngOnInit() {
    this.backend.api('program.get', { programIds: this.programIds })
      .subscribe((rsp: ApiResponseModel) => {
        if (rsp.statusCode !== 'SUCCESS') {
          return
        }

        if (rsp.object.length === 0) {
          return
        }

        this.date = dayjs(`${rsp.object[0].startYYYYMMDD}`, 'YYYYMMDD').format('ddd, Do MMM');

        forEach(rsp.object, (p) => {
          this.sessions.push({
            center: p.groupProp.displayName.replace('Isha ', ''),
            venue: {
              name: p.venueProp.displayName,
              address: p.venueProp.address,
              mapLink: p.venueProp.mapLink,
            },
            country: p.venueProp.country,
          });
        });

        this.isInit = true;
      });
  }
}

