import {Component, Input} from '@angular/core';
import {Program} from '../../program';

@Component({
  selector: 'app-mahashivratri-webinar',
  templateUrl: './mahashivratri-webinar.component.html',
  styleUrls: ['../../register.component.scss']
})
export class MahashivratriWebinarComponent {
  @Input() program: Program;
  constructor() {
  }
}
