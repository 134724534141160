import {Component, Input} from '@angular/core';
import {Program} from '../../program';

@Component({
  selector: 'app-yoga-veera-training-1',
  templateUrl: './yoga-veera-training-1.component.html',
  styleUrls: ['../../register.component.scss']
})
export class YogaVeeraTraining1Component {
  @Input() program: Program;
  constructor() {
  }
}
