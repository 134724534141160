export class FormUtil {
  static appendChildToForm(form: HTMLFormElement, key: string, value: string): void {
    let input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;
    form.appendChild(input);
  }

  static submitForm(form: HTMLFormElement): void {
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }
}
