import {Component} from '@angular/core';

@Component({
  selector: 'app-karma-book-anz',
  templateUrl: './karma-book-anz.component.html',
  styleUrls: ['../../register.component.scss']
})
export class KarmaBookAnzComponent {
  constructor() {
  }
}
