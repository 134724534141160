import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  isAuth: boolean;
  authSub: Subscription;

  year = new Date().getFullYear();

  constructor(private authService: AuthService) {
    this.authSub = this.authService.authChange.subscribe(authStatus => {
      if (authStatus) {
        this.authService.checkAuth().subscribe(isAuth => {
          this.isAuth = isAuth;
        });
      } else {
        this.isAuth = false;
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.authSub.unsubscribe();
  }

  logout() {
    this.authService.logout();
  }
}
