import { Component } from '@angular/core';

@Component({
  selector: 'app-sukku-soffee-with-an-ishanga',
  templateUrl: './sukku-soffee-with-an-ishanga.component.html',
  styleUrls: ['../../register.component.scss']
})
export class SukkuSoffeeWithAnIshangaComponent {
  constructor() {
  }
}
