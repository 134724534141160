import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  { path: '', loadChildren: () => import('./meditator/register/register.module').then(m => m.RegisterModule) },
  { path: '', loadChildren: () => import('./meditator/attend/attend.module').then(m => m.AttendModule) },
  { path: '', loadChildren: () => import('./meditator/contribute/contribute.module').then(m => m.ContributeModule) },
  {
    path: 'practice',
    loadChildren: () => import('./potential-meditator/potential-meditator.module').then(m => m.PotentialMeditatorModule)
  },
  { path: '', loadChildren: () => import('./new-member/new-member.module').then(m => m.NewMemberModule) },
  { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {}
