import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressBarModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import { MAT_DATETIME_FORMATS, MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  imports: [
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatMenuModule,
    MatRippleModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatCardModule,
    MatExpansionModule,
    MatChipsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatListModule,
    MatRadioModule
  ],
  exports: [
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatMenuModule,
    MatRippleModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatCardModule,
    MatExpansionModule,
    MatChipsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatListModule,
    MatRadioModule
  ],
  providers: [
    {
      provide: MAT_DATETIME_FORMATS,
      useValue: {
        parse: {
          dateInput: 'ddd, D MMM YYYY',
          monthInput: 'MMMM',
          timeInput: 'LT',
          datetimeInput: 'ddd, D MMM YYYY LT'
        },
        display: {
          dateInput: 'ddd, D MMM YYYY',
          monthInput: 'MMMM',
          datetimeInput: 'ddd, D MMM YYYY LT',
          timeInput: 'LT',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
          popupHeaderDateLabel: 'ddd, DD MMM'
        }
      }
    }
  ]
})
export class MaterialModule {}
