import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as dayjs from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import { includes } from 'lodash';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthService } from '../../auth/auth.service';
import { User } from '../../auth/user.model';
import { Program } from '../../shared/api-response.model';
import { BackendService } from '../../shared/backend.service';
import { UtilsService } from '../../shared/utils.service';
import { SessionType, SessionTypes, VolunteerMeets } from '../config.data';
import { HighlightedDisplayName } from './session.entity';

dayjs.extend(advancedFormat);
dayjs.extend(isoWeek);

class Session {
  type?: SessionType;
  sessionId?: string;
  displayName: string;
  highlightedDisplayName?: HighlightedDisplayName;
  subtitle?: string;
  img?: string;
  programIds?: number[];
  isRegistered?: boolean;
  registerLink?: string;
  registerPath?: string;
  attendLink?: string;
  attendUrl?: string;
  description?: string;
  language?: string;
  support?: string;
  ttl?: number;
  // hide?: boolean;
  hideAt?: dayjs.Dayjs;
  // whitelistedUsers?: string[];
  buttonLabel?: string;
  buttonColor?: string;
  preconditionProgramIds?: number[];
  preconditionNoShowProgramIds?: number[];
  disabled?: boolean;
  hideForCountries?: string[];
  knowMoreLink?: string;
  hideCard?: boolean;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  init: boolean;
  user: User;
  country: string;
  volunteerSessions: Session[] = [];
  programSessions: Session[] = [];

  volunteeringProgramTypes: string[] = [];
  programTypes: string[] = [];

  volunteerStaticOptions: Session[] = [
    // {
    //   displayName: 'Being a Volunteer (Repeat)',
    //   subtitle: 'Sat, Apr 13th',
    //   attendLink: '/repeat-webinar',
    //   buttonLabel: 'JOIN US',
    //   img: 'beingavolunteer.jpg',
    //   description: 'Join us to know more on how you can get involved!',
    //   hideAt: dayjs('2024-04-13T15:30:00+0800')
    // },
    {
      displayName: 'September Monthly Sangha',
      subtitle: 'Volunteer Sangha (Centre-focused monthly Sangha, every 2nd Sunday)',
      description: 'Focusing on the <em>“Pillars of Isha APAC”</em>, get a glimpse of the various teams and volunteers that ' +
        'support Isha APAC. Through their relentless dedication, they transform the mundane into the sacred, selflessly ' +
        'offering themselves behind the scenes to take this possibility to one and all. #NotToBeMissed😇',
      registerLink: '/hybrid-meet',
      buttonLabel: 'JOIN US',
      img: 'volunteer-sangha.jpg',
      hideAt: dayjs('2024-09-08T12:00:00+0800')
    },
    {
      displayName: 'Guru Purnima Celebrations',
      subtitle: 'Sun, Jul 21 - 6pm (local time)',
      description: '“It is on this day, for the very first time in the history of humanity,' +
        ' that human beings were reminded that they are not a fixed life. ' +
        'If they are willing to strive, every door in the existence is open.” ~ Sadhguru',
      registerLink: '/register-guru-purnima',
      buttonLabel: 'JOIN US',
      img: 'guru-purnima.jpg',
      hideAt: dayjs('2024-07-21T18:00:00+0700')
    },
    {
      displayName: 'Our Online Isha APAC Centre!',
      buttonLabel: 'Step In',
      description: 'A space where we can be together, interact and get inspired from each other for our inner journey.',
      img: 'hangout.jpg',
      attendLink: '/hangout'
    },
    {
      displayName: 'Become a Volunteer',
      buttonLabel: 'Register',
      description: 'Join our Volunteer Database for regular volunteering updates.',
      img: 'register-volunteer.jpg',
      attendLink: '/profile'
    },
    {
      displayName: 'Join Meditator Whatsapp Group',
      buttonLabel: 'Join Us',
      description: '',
      img: 'join-meditator-wa.jpg',
      attendUrl: 'https://ishayoga.life/whatsapp'
    }
  ];

  bannerCarouselImages = [
    { name: 'StayConnected_PortalBanner_v2.jpg', src: null, id: null, link: 'schedule', alt: '' }
  ];
  bannerCarouselOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    items: 1,
    nav: true,
    autoplay: true
  };

  constructor(
    private backend: BackendService,
    private auth: AuthService,
    private router: Router,
    private utils: UtilsService,
    private route: ActivatedRoute
  ) {
    this.bannerCarouselImages.forEach((img, id) => {
      img.src = `assets/banner-carousel/${img.name}`;
      img.id = `${id}`;
    });

    SessionTypes.forEach(st => {
      if (st.orientationWebinar) {
        this.volunteeringProgramTypes.push(st.programType);
      } else if (st.sadhanaSession) {
        this.programTypes.push(st.programType);
      }
    });
    this.volunteeringProgramTypes.push('Volunteer Meet');
  }

  initSessions(programTypes: string[], sessions: Session[], staticOptions: Session[]) {
    this.backend
      .api('program.query', { programType: programTypes, startYYYYMMDD: '_today_', groupIds: [24] })
      .subscribe((programs: any) => {
        const programIds = [];
        programs.object.forEach(p => {
          let sessionType: SessionType;
          let subtitle: string;
          if (p.programTypeProp.displayName === 'Volunteer Meet') {
            sessionType = VolunteerMeets.find((w: SessionType) => w.programType === p.subtitle);
            subtitle = dayjs(p.startYYYYMMDD.toString(), 'YYYYMMDD').format('ddd, MMM Do');
          } else {
            sessionType = SessionTypes.find((w: SessionType) => w.programType === p.programTypeProp.displayName);
            subtitle = dayjs(p.startYYYYMMDD.toString(), 'YYYYMMDD').format('ddd, MMM Do');
          }

          if (!p.language) {
            p.language = 'en';
          }

          if (p.language !== 'en') {
            subtitle += `<br> In ${this.utils.languageName(p.language)}`
          }

          if (!sessionType) {
            return;
          }

          if (p.subtitle.includes('(IEO)')) {
            return;
          }

          if (p.disabled) {
            return;
          }

          const sessionId = `${sessionType.programType}-${p.startYYYYMMDD}`;

          const session = sessions.find(s => s.sessionId === sessionId);
          if (session) {
            session.programIds.push(p.programId);
          } else {
            sessions.push({
              type: sessionType,
              sessionId,
              img: sessionType.img || p.programTypeProp.name + '.jpg',
              displayName: sessionType.displayName || sessionType.programType,
              subtitle,
              description: sessionType.description,
              programIds: [p.programId],
              hideCard: sessionType.hideInGettingInvolved
            });
          }

          programIds.push(p.programId);
        });

        if (staticOptions) {
          staticOptions.forEach(so => {
            if (so.preconditionProgramIds && !this.utils.intersects(this.user.programIds, so.preconditionProgramIds)) {
              return;
            }

            if (
              so.preconditionNoShowProgramIds &&
              !this.utils.intersects(this.user.noShowProgramIds, so.preconditionNoShowProgramIds)
            ) {
              return;
            }

            if (dayjs().isAfter(so.hideAt)) {
              so.hideCard = true;
              return;
            }

            if (includes(so.hideForCountries, this.country)) {
              return;
            }

            sessions.push(so);
            if (so.programIds) {
              programIds.push(...so.programIds);
            }
          });
        }

        this.backend.api('portal.getRegistrations', { programIds }).subscribe((data: any) => {
          // tslint:disable-next-line:no-shadowed-variable
          const programs: Program[] = data.object;
          sessions.forEach(session => {
            if (session.programIds) {
              const isRegistered = !!programs.find(p => session.programIds.includes(p.programId));
              if (!session.registerLink) {
                if (isRegistered) {
                  session.isRegistered = true;
                  session.attendLink = `/attend/${session.programIds.join('-')}`;
                } else {
                  session.registerLink = `/${session.registerPath || 'register'}/${session.programIds.join('-')}`;
                }
              } else if (isRegistered) {
                session.registerLink = null;
              }
            }
          });
        });
      });
  }

  ngOnInit() {
    this.user = this.auth.getUser();

    const nonMeditatorOverride = this.auth.isAdmin() && this.route.snapshot.queryParams.nm === 'true';

    if (this.user.records) {
      this.router.navigate(['/reconcile']).then();
    } else if (this.user.memberId === 0) {
      this.router.navigate(['/create']).then();
    } else if (this.user.isMeditator === false || nonMeditatorOverride) {
      if (localStorage.getItem(`pm-portal-enabled`) === 'true') {
        this.router.navigate(['/practice']).then();
        return;
      }
      this.router.navigate(['/practice/profile-select']).then();
    } else {
      this.init = true;
      this.backend.getCountry().subscribe((country: string) => {
        this.country = country;
        this.initSessions(this.volunteeringProgramTypes, this.volunteerSessions, this.volunteerStaticOptions);
        this.initSessions(this.programTypes, this.programSessions, programStaticOptions(this.user));
        this.initSessions(['Meditator Meet'], [], []);
        this.initSatsangs();
        this.initPracticeCorrectionSessions();
      });
    }
  }

  initSatsangs() {
    const apacGroupIds = [1, 4, 7, 8, 9, 10, 11, 12, 13, 14, 21, 25, 26, 28, 31, 39, 40];
    const params = {
      programType: 'Satsang',
      startYYYYMMDD: dayjs().format('YYYYMMDD'),
      endYYYYMMDD: dayjs().add(27, 'day').format('YYYYMMDD'),
      groupIds: apacGroupIds
    };
    // const groupNameOverrides = {
    //   'Western Australia': 'Perth',
    //   'Queensland': 'Brisbane'
    // };
    const session: Session = {
      displayName: 'Satsang (In Person)',
      knowMoreLink: '/register-satsang',
      buttonLabel: 'KNOW MORE',
      img: 'satsanginperson.jpeg'
    }

    this.backend
      .api('program.query', params)
      .subscribe((programs: any) => {
        const pgms = programs.object;
        if (pgms.length === 0) {
          return;
        }

        const pgmsByDate = {};
        pgms.forEach((p, i) => {
          if (p.disabled) {
            return;
          }

          if (!pgmsByDate[p.startYYYYMMDD]) {
            pgmsByDate[p.startYYYYMMDD] = {
              programIds: [],
              subtitle: dayjs(`${p.startYYYYMMDD}`, 'YYYYMMDD').format('ddd, Do MMM') + ' | ',
            };
          }

          const po = pgmsByDate[p.startYYYYMMDD];

          po.subtitle += `${p.groupProp.displayName.replace('Isha ', '')}`;
          if (i < (pgms.length - 1)) {
            po.subtitle += ', ';
          }

          po.programIds.push(p.programId);
        });

        for (let k in pgmsByDate) {
          const po = pgmsByDate[k];

          this.programSessions.push({
            ...session,
            subtitle: po.subtitle,
            knowMoreLink: session.knowMoreLink+`/${po.programIds.join('-')}`
          });
        }
      });
  }

  initPracticeCorrectionSessions() {
    const params = {
      programType: 'Shambhavi Practice Correction',
      startYYYYMMDD: dayjs().format('YYYYMMDD'),
      endYYYYMMDD: dayjs().add(45, 'day').format('YYYYMMDD'),
      groupIds: [1, 4, 7, 8, 9, 10, 11, 12, 13, 14, 21, 25, 26, 28, 31, 39, 40]
    };
    // const groupNameOverrides = {
    //   'Western Australia': 'Perth',
    //   'Queensland': 'Brisbane'
    // };
    const session: Session = {
      displayName: 'Shambhavi Practice Correction',
      knowMoreLink: '/register-smm-pc',
      buttonLabel: 'KNOW MORE',
      img: 'shambhavipcinperson.jpg'
    }

    this.backend
      .api('program.query', params)
      .subscribe((programs: any) => {
        const pgms = programs.object;
        if (pgms.length === 0) {
          return;
        }

        const pgm = pgms[0];
        const pdt = dayjs(`${pgm.startYYYYMMDD}`, 'YYYYMMDD');
        session.subtitle = '';
        const programIds = [];

        pgms.forEach((p, i) => {
          if (p.disabled) {
            return;
          }

          session.subtitle += `${p.groupProp.displayName.replace('Isha ', '')}`;
          if (i < (pgms.length - 1)) {
            session.subtitle += ', ';
          }

          programIds.push(p.programId);
        });

        if (programIds.length > 0) {
          session.knowMoreLink += `/${programIds.join('-')}`;
          console.log(session.knowMoreLink);
          this.programSessions.push(session);
        }
      });
  }
}

function programStaticOptions(user: User): Session[] {
  return [
    {
      displayName: 'Save Soil: Join The Movement',
      img: 'cp_ss_jm_new.jpeg',
      attendUrl: 'https://save-soil.co/j/' + user.memberID64 + '/' + user.shortAccessKey,
      buttonLabel: 'Register',
      description:
        'Save Soil, is a global movement launched by Sadhguru to save soil from extinction. ' +
        'By becoming an Earth Buddy, you can be a part of this movement and make a difference.',
      hideForCountries: ['CN', 'HK', 'MO']
    },
    {
      displayName: 'Sadhguru Presence Time',
      description: 'Daily at 6.20pm, followed by a video discourse by Sadhguru',
      attendLink: '/presence-time',
      img: 'evening-presence.jpg'
    },
  ];
}
