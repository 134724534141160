import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexModule} from '@angular/flex-layout';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LaddaModule} from 'angular2-ladda';

import {MaterialModule} from '../shared/material.module';
import {CreateComponent} from './create/create.component';

import {Routing} from './new-member.routing';
import {ReconcileComponent} from './reconcile/reconcile.component';
import {CountriesComponent} from './create/countries/countries.component';

@NgModule({
  imports: [
    Routing,
    CommonModule,
    FlexModule,
    MaterialModule,
    CarouselModule,
    FormsModule,
    LaddaModule,
    ReactiveFormsModule,
  ],
  declarations: [CreateComponent, ReconcileComponent, CountriesComponent],
  exports: [CreateComponent, ReconcileComponent, CountriesComponent],
})
export class NewMemberModule {
}
