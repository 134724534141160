import {Component, Input} from '@angular/core';
import {Program} from '../../program';

@Component({
  selector: 'app-corporate-offering-workshop',
  templateUrl: './corporate-offering-workshop.component.html',
  styles: []
})
export class CorporateOfferingWorkshopComponent {
  @Input() program: Program;
  constructor() {
  }
}
