import { Component, Input } from '@angular/core';
import { HighlightedDisplayName } from '../../session.entity';

@Component({
  selector: 'app-highlighted-display-name',
  templateUrl: './highlighted-display-name.component.html',
  styleUrls: ['./highlighted-display-name.component.scss']
})
export class HighlightedDisplayNameComponent {
  @Input() highlightedDisplayName: HighlightedDisplayName;
}
