import {Component, Input} from '@angular/core';
import {Program} from '../../program';

@Component({
  selector: 'app-beyond-inner-engineering',
  templateUrl: './beyond-inner-engineering.component.html',
  styles: []
})
export class BeyondInnerEngineeringComponent {
  @Input() program: Program;
  constructor() {
  }
}
