import {Component} from '@angular/core';

@Component({
  selector: 'app-satsang',
  templateUrl: './satsang.component.html',
  styleUrls: ['../../register.component.scss']
})
export class SatsangComponent {
  constructor() {
  }
}
