import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  scrollToFirstInvalidFormControl(fg: FormGroup, offset = 32): void {
    // Get all controls
    const controls = fg.controls;

    // Find the first invalid control
    for (const name in controls) {
      if (controls[name].invalid) {
        // Get the corresponding DOM element
        const invalidControl = document.querySelector('[formcontrolname="' + name + '"]');

        // Scroll to the invalid control
        const controlPosition = invalidControl.getBoundingClientRect().top;
        const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop || 0;

        window.scrollTo({
          top: controlPosition + scrollPosition - offset,
          behavior: 'smooth'
        });

        break;
      }
    }
  }
}
