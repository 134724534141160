import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { sortBy } from 'lodash';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { User } from '../../../auth/user.model';
import { ApiResponseModel } from '../../../shared/api-response.model';
import { BackendService } from '../../../shared/backend.service';
import { ModalService } from '../../../shared/modal';

class Venue {
  name: string;
  address: string;
  mapLink: string;
}

class Session {
  programId: number;
  center: string;
  venue: Venue;
  country: string;
  regClosed: boolean;
  submitting: boolean;
}

@Component({
  selector: 'app-register-guru-purnima',
  templateUrl: './register-guru-purnima.html',
  styleUrls: ['./register-guru-purnima.scss']
})
export class RegisterGuruPurnimaComponent implements OnInit {

  constructor(private backend: BackendService, private auth: AuthService, private modal: ModalService) {
    this.user = this.auth.getUser();
  }
  user: User;
  isInit: boolean;
  selectedSession: any;
  registeredSession: any;
  isAlreadyRegistered: boolean;
  date = 'Sun, 21st Jul';
  tzMap = {
    Melbourne: 'AEST',
    Sydney: 'AEST',
    Queensland: 'AEST',
    WesternAustralia: 'AWST',
    Canberra: 'AEST',
    Adelaide: 'ACST',
    Singapore: 'SGT',
    Malaysia: 'MYT',
    HongKong: 'HKT',
    Indonesia: 'WIB',
    Philippines: 'PHT',
    Japan: 'JST',
    Thailand: 'ICT',
    NewZealand: 'NZDT',
    SouthKorea: 'KST',
    Vietnam: 'ICT',
    Cambodia: 'ICT',
    Taiwan: 'CST',
  }
  sessions: Session[] = [];
  modalId = 'guru-purnima-session-select';

  ngOnInit() {
    const programIds: number[] = [];

    this.backend.api('program.query', { programType: 'Guru Purnima', startYYYYMMDD: '_today_', allGroups: true })
      .subscribe((rsp: ApiResponseModel) => {
        _.forEach(rsp.object, (p) => {

          if (p.disabled) {
            return;
          }

          const session: Session = {
            programId: p.programId,
            center: p.groupProp.displayName.replace('Isha ', ''),
            regClosed: p.isRegistrationLimitReached,
            venue: {
              name: p.venueProp.displayName,
              address: p.venueProp.address,
              mapLink: p.venueProp.mapLink,
            },
            country: p.venueProp.country,
            submitting: false,
          };

          this.sessions.push(session);
          programIds.push(p.programId);
        });

        this.backend.api('portal.getRegisteredPrograms', {programIds})
          .pipe(
            finalize(() => {
              this.backend.getCountry().subscribe(
                (country: string) => {
                  this.sessions = sortBy(this.sessions, [(c) => c.country !== country]);
                  this.isInit = true;
                });
            })
          )
          .subscribe(
            (rsp2: ApiResponseModel) => {
              this.isAlreadyRegistered = rsp2.object && rsp2.object.length > 0;
              _.forEach(rsp2.object, (p) => {
                const registeredProgram = this.getSession(p);
                if (registeredProgram) {
                  this.registeredSession = registeredProgram;
                }
              });
            }
          );
      });
  }

  register(session) {
    session.submitting = true;
    this.backend.api('portal.register', {programId: session.programId, memberId: this.user.memberId})
      .pipe(
        finalize(() => {
          session.submitting = false;
        })
      )
      .subscribe(
        (rsp: ApiResponseModel) => {
          if (rsp.statusCode === 'SUCCESS') {
            this.registeredSession = this.getSession(rsp.object);
            this.closeModal();
          }
        }
      );
  }

  getSession(p) {
    return _.find(this.sessions, (sess) => sess.programId === p.programId);
  }

  openModal(session) {
    this.selectedSession = session;
    this.modal.open(this.modalId);
  }

  closeModal() {
    this.modal.close(this.modalId);
  }

}
