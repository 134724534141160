import { Component } from '@angular/core';

@Component({
  selector: 'app-sia-vol-meet',
  templateUrl: './sia-vol-meet.component.html',
  styleUrls: ['../../register.component.scss']
})
export class SiAVolMeetComponent {
  constructor() {
  }
}
