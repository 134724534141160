import { Component } from '@angular/core';

@Component({
  selector: 'app-bhavaspandana-ppm',
  templateUrl: './bhavaspandana-ppm.component.html',
  styleUrls: ['../../register.component.scss']
})
export class BhavaspandanaPpmComponent {
  constructor() {
  }
}
