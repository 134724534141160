import { Component, Input } from '@angular/core';
import { Program } from '../../program';

@Component({
  selector: 'app-ananda-alai',
  templateUrl: './ananda-alai.component.html',
  styleUrls: []
})
export class AnandaAlaiComponent {
  @Input() program: Program;
  constructor() {}
}
