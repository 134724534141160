import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { User } from '../../auth/user.model';
import { BackendService } from '../../shared/backend.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: User;
  authSub: Subscription;
  loadingSub: Subscription;
  loading = false;

  routes$ = combineLatest([this.router.events.pipe(startWith(null)), this.route.url.pipe(startWith([]))]).pipe(
    map(([e, u]) => {
      const url = (u && u[0] && u[0].path) || (e instanceof NavigationEnd && e.url) || this.router.url;
      return url;
    })
  );

  switchProfileRoute$ = this.routes$.pipe(map(routeUrl => (this.isPracticeRoute(routeUrl) ? '/' : '/practice')));

  switchProfileToolTip$ = this.routes$.pipe(
    map(routeUrl =>
      this.isPracticeRoute(routeUrl) ? 'Swtich to IE meditator portal' : 'Switch to beginner practice portal'
    )
  );

  homeRoute$ = this.routes$.pipe(map(routeUrl => (this.isPracticeRoute(routeUrl) ? '/practice' : '/')));

  isPracticeRoute = (routeUrl: string) => routeUrl && routeUrl.startsWith('/practice');

  constructor(
    private authService: AuthService,
    private backendService: BackendService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.authSub = this.authService.authChange.subscribe(authStatus => {
      // console.log('auth changed ' + authStatus);
      if (authStatus) {
        this.authService.checkAuth().subscribe(isAuth => {
          this.user = this.authService.getUser();
          // console.log(this.user);
        });
      } else {
        this.user = null;
      }
    });
    this.loadingSub = this.backendService.loadingChange.subscribe(loading => {
      // console.log(loading);
      this.loading = loading;
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.authSub.unsubscribe();
    this.loadingSub.unsubscribe();
  }

  logout() {
    this.authService.logout();
  }

  navigateHome() {
    const route = this.user && this.user.isMeditator ? `/` : `/p`;
    this.router.navigate([route]);
    return;
  }
}
