import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {COUNTRIES, Country} from './countries.data';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {MatSelect} from '@angular/material';
import {take, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CountriesComponent,
      multi: true
    }
  ]
})
export class CountriesComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {

  protected countries: Country[] = COUNTRIES;
  public countryCtrl: FormControl = new FormControl();
  public countryFilterCtrl: FormControl = new FormControl();
  public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);

  @ViewChild('countrySelect', {static: false}) countrySelect: MatSelect;

  protected onDestroy = new Subject<void>();
  private onChange: (_: any) => void;
  private onTouched: (_: any) => void;

  constructor() {
  }

  ngOnInit() {
    this.filteredCountries.next(this.countries.slice());

    this.countryCtrl.valueChanges
      .subscribe(() => {
        if (this.onChange) {
          this.onChange(this.countryCtrl.value.id);
        }
      });

    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  protected setInitialValue() {
    this.filteredCountries
      .pipe(take(1), takeUntil(this.onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredCountries are loaded initially
        // and after the mat-option elements are available
        this.countrySelect.compareWith = (a: Country, b: Country) => a && b && a.id === b.id;
      });
  }

  protected filterBanks() {
    if (!this.countries) {
      return;
    }

    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountries.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCountries.next(
      this.countries.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  writeValue(countryCode: string) {
    if (countryCode === null) {
      this.countryCtrl.setValue(null);
    } else {
      const found = this.countries.find((cc) => cc.id === countryCode);
      if (found) {
        this.countryCtrl.setValue(found);
      }
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
