import {Component, Input} from '@angular/core';
import {Program} from '../../program';

@Component({
  selector: 'app-pledge-video-workshop',
  templateUrl: './pledge-video-workshop.html',
  styleUrls: ['../../register.component.scss']
})
export class PledgeVideoWorkshopComponent {
  @Input() program: Program;
  constructor() {
  }
}
